// Notificaciones

// Mensajes

// Selector botón cerrar
const messageBtnClose = Array.from(
  document.getElementsByClassName("ag-js-messageClose")
);
// Verifica si existe el botón cerrar y agrega el evento
if (messageBtnClose[0]) {
  // Agrega evento cerrar al botón cerrar
  messageBtnClose.forEach(x => {
    const messageItem = x.closest(".ag-js-messageItem");
    const waitingTimeToVisible = 1000;

    // Muestra la alerta 1 segundo (valor guardado en la variable waitingTimeToVisible) después de cargar la pagina
    setTimeout(() => {
      messageItem.classList.remove("ag-is-messageHidden");
      messageItem.classList.add("ag-has-messageOpen");
    }, waitingTimeToVisible);

    // Al hacer click en el botón cerrar agrega clases CSS: animaciones y cierra el mensaje
    x.addEventListener("click", function() {
      messageItem.classList.add("ag-has-messageClose");
      messageItem.classList.remove("ag-has-messageOpen");
      setTimeout(() => {
        messageItem.classList.remove("ag-has-messageClose");
        messageItem.classList.add("ag-is-messageHidden");
      }, 1200);
    });
  });
}
